import React from 'react';
import { graphql } from 'gatsby';
import {
  TRANSPARENT, PLAN, LARGE, SINGLE_COL, LC_BLACK,
} from '@constants';
import {
  Layout, ContentLayout, BasicDarkHero, BasicRichText,
} from '@molecules';
import { setSearchParams, getKeyValue } from '@utils';

const PolicyDetailPageTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { mobileDisclaimer, donationDisclaimer, siteTitle } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent, disableAlertBar,
    enLanguagePageLinks, pageInOtherLanguages, disableDonationBar,
  } = page || {};
  const {
    title: pageTitle, intro, heroImage, contentTypeLabel, titleEyebrow,
    content,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  const noImage = !heroImage || !heroImage.fluid;

  const searchParams = setSearchParams(location);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription && metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={TRANSPARENT}
      settings={settings}
      navAnimate
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableDonationBar={disableDonationBar}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
    >
      <BasicDarkHero
        eyebrow={titleEyebrow}
        title={pageTitle}
        publisher={contentTypeLabel}
        heroImage={heroImage}
        pageType={PLAN}
      />
      <ContentLayout layout={`${SINGLE_COL}${noImage ? 'noimage' : ''}`}>
        {intro && (
          <BasicRichText
            content={intro}
            size={LARGE}
            textWidth={SINGLE_COL}
            color={LC_BLACK}
            location={location}
          />
        )}
        {content && (
          <BasicRichText
            content={content}
            textWidth={SINGLE_COL}
            color={LC_BLACK}
            pageType={PLAN}
            mobileDisclaimer={mobileDisclaimer}
            donationDisclaimer={donationDisclaimer}
          />
        )}
      </ContentLayout>
    </Layout>
  );
};

export default PolicyDetailPageTemplate;

export const policyDetailPageQuery = graphql`
  query PolicyDetailPageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentPolicyDetail {
          titleEyebrow
          title
          id
          publicationDate(formatString: "MMM D, YYYY")
          contentTypeLabel
          heroImage {
            fluid(quality: 90, maxWidth: 700) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
          summary {
            raw
          }
          intro {
            raw
          }
          content {
            raw
            references {
              ... on ContentfulPage {
                contentful_id
                path
              }
              ... on ContentfulRedirect {
                contentful_id
                from
                to
              }
              ... on ContentfulComponentImageCollage {
                contentful_id
                id
                internal {
                  type
                }
                imageLayout
                images {
                  fluid(quality: 90, maxWidth: 700) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                  description
                  contentful_id
                }
                caption {
                  raw
                }
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
              ... on ContentfulComponentMediaEmbed {
                contentful_id
                id
                internal {
                  type
                }
                mediaTitle
                mediaType
                mediaUid
                imageOrCustomThumbnail {
                  fluid(quality: 90, maxWidth: 1170) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                mediaDescription {
                  raw
                }
              }
              ... on ContentfulComponentDonationAsk {
                contentful_id
                id
                internal {
                  type
                }
                desktopDonationButtons {
                  key
                  id
                  value
                }
                mobileDonationButtons {
                  key
                  id
                  value
                }
                donationLink
                header
                intro {
                  raw
                }
              }
              ... on ContentfulComponentForm {
                contentful_id
                id
                internal {
                  type
                }
                intro {
                  raw
                }
                header
                formId
                defaultFormSourcing
                formType
                submitButtonLabel
                postSubmitMessage {
                  raw
                }
                postSubmitRedirect
                postSubmitDonationAsk {
                  id
                  header
                  intro {
                    raw
                  }
                  desktopDonationButtons {
                    key
                    id
                    value
                  }
                  mobileDonationButtons {
                    key
                    id
                    value
                  }
                  donationLink
                  header
                }
                contentfulfields {
                  label
                  name
                  required
                  id
                  type
                  desktopWidth
                  mobileWidth
                  selectradiocheckboxOptions {
                    key
                    value
                  }
                }
                submitButtonDesktopWidth
                submitButtonMobileWidth
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
